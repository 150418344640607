<template>
	<Toast :group="group" />
</template>

<script setup lang="ts">
	const { generateOrgMessageStore } = useStores();
	const { isAiGenerateMessage, isAiGeneratedSuccess } = storeToRefs(generateOrgMessageStore);
	const toast = useToast();

	const group = "ai-org-message-toast";

	const showInfo = () => {
		toast.add({
			severity: "info",
			summary: "Генерация сообщения",
			detail: "Пожалуйста, оставайтесь на странице и дождитесь завершения генерации.",
			group,
		});
	};

	const showSuccess = () => {
		toast.add({
			severity: "success",
			summary: "Генерация сообщения",
			detail: "Сообщение успешно сгенерировано.",
			group,
			life: 3000,
		});
	};

	const showError = () => {
		toast.add({
			severity: "error",
			summary: "Генерация сообщения",
			detail: "Ошибка генерации сообщения",
			group,
			life: 3000,
		});
	};

	watch(
		() => isAiGenerateMessage.value,
		(value) => {
			toast.removeGroup(group);

			if (value) {
				showInfo();
			} else {
				if (isAiGeneratedSuccess.value) {
					showSuccess();
				} else {
					showError();
				}
			}
		},
	);
</script>
